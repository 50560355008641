import { Router, createRouter as _createRouter, createWebHistory } from 'vue-router'

const defaultComponents = {
  header: () => import('@/components/Header.vue'),
  sidebar: () => import('@/components/Sidebar.vue'),
}

const createRouter = (/*_config: Config*/): Router => {
  return _createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
      {
        path: '/auth/callback',
        name: 'auth-callback',
        component: import('@/components/LoadingBlock.vue'),
        meta: {
          skipAuthentication: true,
        },
      },
      {
        path: '/',
        name: 'dashboard',
        components: {
          ...defaultComponents,
          main: () => import('@/views/Home.vue'),
        },
      },
      {
        path: '/place/list',
        name: 'listAnkorPlaces',
        components: {
          ...defaultComponents,
          main: () => import('@/components/place/ListPlace.vue'),
        },
      },
      {
        path: '/place/:uri/editor',
        name: 'placeeditor',
        components: {
          ...defaultComponents,
          main: () => import('@/components/place/PlaceEditor.vue'),
        },
        props: {
          main: (route) => ({
            uri: decodeURIComponent(route.params.uri.toString()),
          }),
        },
      },
      {
        path: '/placelist/export',
        name: 'placelistexport',
        components: {
          ...defaultComponents,
          main: () => import('@/components/place/PlaceListExport.vue'),
        },
      },
      {
        path: '/feed/specials',
        name: 'specials',
        components: {
          ...defaultComponents,
          main: () => import('@/views/feed/Specials.vue'),
        },
      },
      {
        path: '/feed/charter-requests',
        name: 'charter-requests',
        components: {
          ...defaultComponents,
          main: () => import('@/views/feed/CharterRequests.vue'),
        },
      },
      {
        path: '/geo/',
        name: 'geo',
        components: {
          ...defaultComponents,
          main: () => import('@/components/geo/MapNavigator.vue'),
        },
      },
      {
        path: '/geo/list',
        name: 'listGeo',
        components: {
          ...defaultComponents,
          main: () => import('@/components/geo/ListGeo.vue'),
        },
      },
      {
        path: '/geo/:uri/editor',
        name: 'geoeditor',
        components: {
          ...defaultComponents,
          main: () => import('@/components/geo/GeoEditor.vue'),
        },
        props: {
          main: (route) => ({
            uri: decodeURIComponent(route.params.uri.toString()),
          }),
        },
      },
      {
        path: '/geo-vessel/',
        name: 'geo-vessel',
        components: {
          ...defaultComponents,
          main: () => import('@/components/geo/VesselMapNavigator.vue'),
        },
      },
      {
        path: '/vessel/:uri/editor',
        name: 'addvessel',
        components: {
          ...defaultComponents,
          main: () => import('@/components/vessel/VesselEditor.vue'),
        },
        props: {
          main: (route) => ({
            uri: decodeURIComponent(route.params.uri.toString()),
          }),
        },
      },
      {
        path: '/vessel/stats',
        name: 'vesselStats',
        components: {
          ...defaultComponents,
          main: () => import('@/components/vessel/stats/VesselStats.vue'),
        },
      },
      {
        path: '/itinerary/copyToAnkor',
        name: 'copyItineraryToAnkor',
        components: {
          ...defaultComponents,
          main: () => import('@/components/itinerary/CopyToAnkor.vue'),
        },
      },
      {
        path: '/itinerary/list',
        name: 'listAnkorItineraries',
        components: {
          ...defaultComponents,
          main: () => import('@/components/itinerary/ListItinerary.vue'),
        },
      },
      {
        path: '/vessel/list',
        name: 'listAnkorVessels',
        components: {
          ...defaultComponents,
          main: () => import('@/components/vessel/ListVessel.vue'),
        },
      },
      {
        path: '/users',
        name: 'users',
        components: {
          ...defaultComponents,
          main: () => import('@/components/user/User.vue'),
        },
      },
      {
        path: '/user/copyToUser',
        name: 'copyToUser',
        components: {
          ...defaultComponents,
          main: () => import('@/components/user/CopyToUser.vue'),
        },
      },
      {
        path: '/user/:id/details',
        name: 'users-details',
        components: {
          ...defaultComponents,
          main: () => import('@/components/user/UserDetails.vue'),
        },
        props: {
          main: (route) => ({
            id: decodeURIComponent(route.params.id.toString()),
          }),
        },
      },
      {
        path: '/troubleshoot/istion',
        name: 'istion-dashboard',
        components: {
          ...defaultComponents,
          main: () => import('@/components/istion/Dashboard.vue'),
        },
      },
      {
        path: '/integrations',
        name: 'integrations',
        components: {
          ...defaultComponents,
          main: () => import('@/views/Integrations.vue'),
        },
      },
      {
        path: '/unauthorized',
        name: 'unauthorized',
        components: {
          main: () => import('@/views/Unauthorized.vue'),
        },
        meta: {
          skipAuthentication: true,
        },
      },
      {
        path: '/:pathMatch(.*)*',
        name: '404',
        components: {
          main: () => import('@/views/NotFound.vue'),
        },
        meta: {
          skipAuthentication: true,
        },
      },
    ],
  })
}

export default createRouter
